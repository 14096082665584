import { Typography,Box } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const DematAccountCharges = () =>{
  const dematAccountCharges = [
    {"stepNo":1,"title":"Account Opening Fees","stepName":"It is a one-time cost incurred at the time of opening a Demat Account. SMC Global Securities offers a free demat account opening online facility."},
    {"stepNo":2,"title":"AMC","stepName":"Account Maintenance Charges are fees charged by the stockbroker to manage your account. SMC Global Securities does not charge any AMC in the first year."},
    {"stepNo":3,"title":"Transaction Cost","stepName":"Transaction cost is charged as a flat fee or on the basis of the transaction value. Every time you buy or sell any security, the transaction fee is levied."},
    {"stepNo":4,"title":"DP Charges","stepName": (
      <><Link to="https://blog.smctradeonline.com:7171/dp-charges-explained-what-is-the-dp-charges-in-smc-global-securities/" rel="noopener noreferrer">DP charges</Link> are paid to the depositories (NSDL or CDSL) whenever you make any sell-side transaction.</>
  )}
]

    return(
        <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="authorised">
              <div className="container">
                <div className="row">
                  <div className="web-container">
                    <div className="title">
                      <h3>Charges in Opening Demat Account</h3>
                    </div>
                    <div className="iconrow">
                      {dematAccountCharges.map(step=>
                    <Box
                        p={1}
                        borderRadius={2}
                        bgcolor="#FFF"
                        display="flex"
                        flexDirection="row"
                        // alignItems="center"
                        height="auto"
                    // textAlign="center"
                    >
                        {/* Number */}
                        <Box
                            bgcolor="#C6F5EE"
                            p={2}
                            borderRadius="50%"
                            width={30}
                            height={30}
                            justifyContent="center"
                            alignItems="center"
                            display="flex"
                        >
                            <Typography
                                variant="h6"
                                fontWeight="bold"
                                color="#058C7A"

                            // gutterBottom
                            >
                                {step?.stepNo}
                            </Typography>
                        </Box>

                        {/* Title */}
                        <Box sx={{ pl: 2 }}>
                            
                            <Typography lineHeight={1} sx={{paddingBottom:1}} variant="body1" fontWeight="bold" gutterBottom={false}>
                                      {step?.title}
                            </Typography>
                            <Typography lineHeight={1} variant="body1" color="textSecondary">
                                      {step?.stepName}
                            </Typography>
                            
                        </Box>
                    </Box>
                    )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    )
}
export default DematAccountCharges;