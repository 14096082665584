import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useContext } from "react";
import { Context } from "../context";
import Logo from "../images/smc_ico.webp";
const AppBanner = () => {
  const { deviceID } = useContext(Context);
  return (
    <Box
      sx={{
        position: "fixed", // Fixed position to stick at the bottom
        bottom: 0, // Align it at the bottom
        left: 0, // Stretch to the left edge
        right: 0, // Stretch to the right edge
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "#f3f3f3",
        borderRadius: "8px 8px 0 0", // Rounded corners at the top only
        padding: "12px 16px",
        maxWidth: "100%", // Stretch full width
        boxShadow: "0 -4px 6px rgba(0, 0, 0, 0.1)", // Shadow on top for depth
        zIndex: 1000, // Ensure it's above other content
      }}
    >
      {/* App Logo */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <Box
          component="img"
          src={Logo} // Replace with your logo path
          alt="App Logo"
          sx={{
            width: "48px",
            height: "48px",
            borderRadius: "8px",
          }}
        />
        <Box>
          <Typography variant="body1" fontWeight="bold" sx={{ color: "#333" }}>
            Join 9 Lacs+ Users Today!
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: "#ffa726", fontSize: "14px" }}
          >
            ★ ★ ★ ★ ★
          </Typography>
        </Box>
      </Box>
      {/* Action Button */}
      <Button
        variant="contained"
        sx={{
          background: "linear-gradient(80deg, #405cb2, #44b6a7)",
          color: "#fff",
          borderRadius: "20px",
          padding: "6px 16px",
          textTransform: "none",
        }}
        onClick={() => {
          // Redirect to your app's store link
          window.location.href =
            deviceID === "Android device."
              ? "https://play.google.com/store/apps/details?id=com.smc.ace"
              : "https://apps.apple.com/in/app/smc-ace-2-0/id6451310604";
        }}
      >
        Get the App
      </Button>
    </Box>
  );
};
export default AppBanner;
