import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Link } from "react-router-dom";

const DematFaq = () => {
  return (
    <>
      <div
        className="webcent"
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-duration="600"
        data-aos-once="true"
        data-aos-easing="ease-in-sine"
      >
        <div className="container">
          <div className="row">
            <div className="web-container">
              <div className="title">
                <h3>Frequently Asked Questions</h3>
              </div>
              <div className="faqcont">
                <Accordion allowZeroExpanded="true">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can I open a Demat account for free?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                        Yes, you can open free demat account with SMC Global Securities or you can also download
                        the SMC ACE App from the Play Store.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                      Who needs a Demat account?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                        An Indian resident or an NRI (Non-Resident Indian) who wishes to trade in the Indian stock
market will need a Demat account. The NRIs can open repatriable or non-repatriable Demat
accounts.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the different types of Demat Account
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                        The three types of Demat account offerings in India are:
                        </p>
                        <ul>
                          <li>
                          Regular Demat account for Indian residents.
                          </li>
                          <li>
                          Repatriable Demat account for NRIs who also want to transfer the funds abroad.
                          </li>
                          <li>
                          Non-Repatriable Demat account for NRIs who do not want to transfer the funds back to
                          abroad.
                          </li>
                        </ul>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                      What is a Demat account used for?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                        A Demat account is used for holding stocks, mutual funds, bonds, ETFs, F&Os, etc. in
                        electronic form.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                      Is Demat account necessary to apply for an IPO?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                        Demat account is not a necessity to <Link to={"https://www.smctradeonline.com/invest/ipo"}>apply for an IPO</Link> but once you get the allotment of shares
                        you can only hold them in a dematerialised form.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                      What are Demat charges?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                        Demat charges include the account opening fees, account maintenance fees, transaction
charges, STT, etc. This can vary for stocks and <Link to={"https://www.smctradeonline.com/trade"}>futures and options</Link> as well as for intraday and
delivery trading.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                      What are the steps to open Demat account?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                      <ul>
                            <li>
                            Fill out the account opening form
                            </li>
                            <li>
                              {/* • */}
                              Upload the KYC documents
                            </li>
                            <li>Verify and E-sign</li>
                          </ul>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                      What is the function of DP in a demat account?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                        DP (Depository Participant) is an intermediary that provides dematerialization or
                        rematerialization services to investors.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                      Can I withdraw money from a Demat account?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                        All the sale proceeds from securities are directly credited to your linked bank account. So, there
                        is no need to withdraw any amount from a Demat account.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                      Is a trading account needed with a Demat account?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                        Yes, a trading account is needed to place the order because the Demat account only holds the
                        bought securities.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                      Can I hold more than one Demat account?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                        Yes, you can have <Link to={"https://blog.smctradeonline.com:7171/can-i-have-2-demat-accounts-legalities-benefits-explained/"}>more than one Demat account</Link> but one broker allows only one account opening.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                      Can I hold mutual funds in a Demat account?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                        Yes, you can hold mutual funds in a Demat account but it is not a compulsion as in the case of stocks.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                      Can a nominee be added to a Demat account?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                        Yes, the nominee can be added to a Demat account for easy transfer of securities in case of the
                        beneficiary’s death. You can appoint more than one nominee.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                      How much time does it take to open Demat account online?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                        The Demat account online opening process may take 15 minutes to an hour to complete.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                      Can I transfer my Demat account to another DP?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                        Yes, the Demat account can be transferred to another DP by filling out the form.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                      Can I open Demat account jointly?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                        A Demat account can be opened jointly like a bank account.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                      Do I need to have a Demat account to start a SIP?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                        For starting a SIP in any mutual funds, Demat account is not mandatory.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                      Can I link my Demat account with a trading account?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="accordiancont">
                        <p>
                        Yes, you can link your Demat account with your trading account for smooth transactions of securities.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DematFaq;
