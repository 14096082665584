import React from "react";
import Webinar from "../images/webinarn.webp";
import Postcast from "../images/pocastn.webp";
import Blog from "../images/blogn.webp";
import Tradimg from "../images/download-app.webp";
import Trading from "../images/download-appn.webp";
import Ios from "../images/app.webp";
import Android from "../images/google.webp";
import { Link, NavLink } from "react-router-dom";
import { ANDROID_LINK, IOS_LINK } from "../utils/linkConstants";

export const Learning = () => {
  return (
    <>
      <div
        className="webcent "
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-duration="600"
        data-aos-once="true"
        data-aos-easing="ease-in-sine"
      >
        {/* <div className="container">
          <div className="row">
            <div className="web-container">
              <div className="downloadapp">
                <div className="applef">
                  <div className="mobileno">
                    <img
                      src="./images/download-appn.webp"
                      className="responsive"
                      style={{ width: 381, height: 296 }}
                      alt=""
                    />
                  </div>
                </div>
                <div className="apprgt">
                  <div className="downloadlinks">
                    <figure>
                      {" "}
                      <img
                        src="./images/download-app.webp"
                        alt="Download"
                        className="responsive"
                        style={{ width: 78, height: 79 }}
                      />
                    </figure>
                    <div className="downloadcont">
                      <h4 style={{ textTransform: "none" }}>
                        Download SMC ACE App for a Seamless Trading Experience
                      </h4>
                      <div className="downloadimg">
                        <span>
                          <NavLink to={ANDROID_LINK} target="_blank">
                            <img
                              src="./images/google.webp"
                              alt="Play Store"
                              className="responsive"
                              style={{ width: 150, height: 44 }}
                            />
                          </NavLink>
                        </span>
                        <span>
                          <NavLink to={IOS_LINK} target="_blank">
                            <img
                              src="./images/app.webp"
                              alt="IOS"
                              className="responsive"
                              style={{ width: 150, height: 44 }}
                            />
                          </NavLink>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="listenwatch p-100 gradient whitetext">
          <div className="container">
            <div className="row">
              <div className="web-container">
                <div className="title">
                  <h3>Knowledge is Power</h3>
                  <h4>We make it simpler for seamless Investment journey.</h4>
                </div>
                <div className="webrow">
                  <div className="webcol">
                    {/* <Link to="/invest/webinar"> */}
                    <div className="webimg">
                      <span>
                        <img
                          src="./images/webinarn.webp"
                          alt="Webinar"
                          className="responsive"
                          style={{ width: 120, height: 120 }}
                        />
                      </span>
                      <h4>Webinar</h4>
                    </div>
                    {/* </Link> */}
                  </div>
                  <div className="webcol">
                    <div className="webimg">
                      <span>
                        <img
                          src="./images/blogn.webp"
                          alt="Postcast"
                          className="responsive"
                          style={{ width: 120, height: 120 }}
                        />
                      </span>
                      <h4>Blog</h4>
                    </div>
                  </div>
                  <div className="webcol">
                    <div className="webimg">
                      <span>
                        <img
                          src="./images/pocastn.webp"
                          alt="Blog"
                          className="responsive"
                          style={{ width: 120, height: 120 }}
                        />
                      </span>
                      <h4>Podcast</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
