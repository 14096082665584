import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import validator from "validator";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { leadApi, sendOtpApi } from "../utils/APICollection";
import { useContext } from "react";
import { Context } from "../context";
import axios from "axios";
import { lCategoryList } from "../data";
import "react-toastify/dist/ReactToastify.css";
import { loadReCaptchaScript } from "./common/FormattedDate";
import { CAPTCHA_KEY } from "../utils/constants";

const ContactForm = ({ catID, smsType }) => {
  const initialObject = {
    category: "",
    subCategory: "",
    fullName: "",
    emailId: "",
    mobileNumber: "",
    description: "",
  };

  const navigate = useNavigate();

  const {
    num,
    setNum,
    name,
    setName,
    setCategoryID,
    setUrlData,
    ipAddress,
    setIPAddress,
    urlData,
    categoryID,
    loading,
    setLoading,
    deviceID,
    setDeviceID,
    isLeadCapture,
    setIsLeadCapture,
    source,
    setSource,
    setLeadId
  } = useContext(Context);

  const [fullName, setFullName] = React.useState("");
  const [emailId, setEmailId] = React.useState("");
  const [mobileNumber, setMobileNumber] = React.useState("");
  const [category, setCategory] = React.useState("");
  const [subCategory, setSubCategory] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [mobileError, setMobileError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [nameError, setNameError] = React.useState("");
  const [catError, setCatError] = React.useState("");
  const [subCatError, setSubCatError] = React.useState("");
  const [descriptionError, setDescriptionError] = React.useState("");

  const [searchParams, setSearchParams] = useSearchParams();
  const [subCate, setSubCate] = React.useState([]);

  searchParams.get("refcode");

  const handleName = (e) => {
    setFullName(
      e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
    );
    setNameError("");
    setName(e.target.value);
  };
  const handleEmail = (e) => {
    setEmailId(e.target.value);
    setEmailError("");
  };
  const handleMobile = (e) => {
    setMobileNumber(e.target.value);
    setMobileError("");

    setNum(e.target.value);
  };
  const handleCategory = (e) => {
    setCategory(e.target.value);
    setCatError("");
  };

  const handleSubCategory = (e) => {
    setSubCategory(e.target.value);
    setSubCatError("");
  };

  const handleDescription = (e) => {
    setDescription(e.target.value);
    setDescriptionError("");
  };

  const changeSubCat = () => {
    const subCat = lCategoryList?.filter((item) => item.value == category);
    const finalSubCat = subCat.map((item) => item);
    setSubCate(finalSubCat);
  };

  React.useEffect(() => {
    changeSubCat();
  }, [category]);

  const emptyField = () => {
    setFullName("");
    setEmailId("");
    setMobileNumber("");
    setCategory("");
    setSubCategory("");
    setDescription("");
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLeadCapture(true);
    if (loading) {
      return;
    }
    setLoading(true);

    let mobErr = "";
    let namErr = "";
    let catErr = "";
    let subCatErr = "";
    let emailErr = "";
    let descErr = "";

    if (!validator.isEmail(emailId)) {
      emailErr = "Enter a Valid Email ID";
    }

    if (
      !fullName ||
      fullName.trim() === "" ||
      fullName?.length < 3 ||
      fullName?.length > 50
    ) {
      namErr = "Please fill out this field";
    }

    if (mobileNumber?.toString().length !== 10) {
      mobErr = "Please fill out this field";
    }

    let reg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (reg.test(emailId) === false) {
      emailErr = "Enter a Valid Email ID";
    }

    if (category == "") {
      catErr = "Please fill out this field";
    }
    if (subCategory == "") {
      subCatErr = "Please fill out this field";
    }

    if (
      !description ||
      description.trim() === "" ||
      description?.length < 1 ||
      description?.length > 50
    ) {
      descErr = "Please fill out this field";
    }

    if (emailErr || mobErr || namErr || catErr || subCatErr || descErr) {
      setEmailError(emailErr);
      setMobileError(mobErr);
      setNameError(namErr);
      setCatError(catErr);
      setSubCatError(subCatErr);
      setDescriptionError(descErr);
      setLoading(false);
    } else {
      try {
        // const { data, error } = await register({ mobile_no: mobileNumber });
        // if (data?.stutus == "success") {
        getIP();

        setCategoryID(catID);

        setUrlData({
          ref: searchParams.get("utm_refcode"),
          utm_source: searchParams.get("utm_source"),
          medium: searchParams.get("utm_medium"),
          campaign: searchParams.get("utm_campaign"),
        });
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(String(CAPTCHA_KEY), { action: "submit" })
            .then(async (token) => {
              const { data, message } = await leadApi({
                mobile_no: mobileNumber,
                captchaToken: token,
                leadInfo: [
                  {
                    user_name: fullName,
                    contact_number: mobileNumber,
                    email: emailId,
                    city: "",
                    state: "",
                    category_code: "Contact",
                    source: "Smc Website | Contact",
                    ref: searchParams.get("utm_refcode"),
                    utm_source: searchParams.get("utm_source"),
                    medium: searchParams.get("utm_medium"),
                    campaign: searchParams.get("utm_campaign"),
                    country: "",
                    product: "Contact",
                    ip: ipAddress,
                    device: deviceID,
                    status: "",
                    client_id: "",
                    sub_query: `${category} || ${subCategory}`,
                    existing_customer: "",
                    explain_query: description,
                  },
                ],
              });
              if (data) {
                setLeadId(String(data?.data[0]));
                window.grecaptcha.ready(() => {
                  window.grecaptcha
                    .execute(String(CAPTCHA_KEY), { action: "submit" })
                    .then(async (token) => {
                      const { data: data2, message2 } = await sendOtpApi({
                        mobile_no: mobileNumber,
                        sms_type: smsType ? smsType : "sms_common",
                        user_name: fullName,
                        captchaToken: token,
                      });
                      if (data2) {
                        setLoading(false);
                        navigate("/validateotp");
                      }
                    });
                });
              } else {
                setLoading(false);
              }

              {
                emptyField();
              }
            });
        });
        // } else {
        //   setLoading(false);
        // }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
      // finally {
      //   setLoading(false);
      // }
    }
  };

  const getIP = async () => {
    try {
      const userAgent = navigator.userAgent;
      const res = await axios.get("https://api.ipify.org?format=json");

      setIPAddress(res?.data?.ip);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let userAgent = navigator.userAgent;
    setSource("Smc Website | Contact");
    if (/Android/i.test(userAgent)) {
      setDeviceID("Android device.");
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      setDeviceID("iOS device.");
    } else if (/Macintosh/i.test(userAgent)) {
      setDeviceID("macOS device.");
    } else if (/Windows/i.test(userAgent)) {
      setDeviceID("Windows device.");
    } else {
      setDeviceID("Unknown Device");
    }
    getIP();
    loadReCaptchaScript();
  }, []);

  React.useEffect(() => {
    getIP();
  }, []);

  const ALPHA_REGEX = /^[a-zA-Z ]+$/;
  let emailErr = "";
  const reg = /^[a-zA-Z0-9_\-\.\@'’]/;

  if (reg.test(emailId) === false) {
    emailErr = "Enter a Valid Email ID";
  }

  return (
    <>
      <div className="col-12 pull-left">
        <div className="title">
          <h3>Leave a query</h3>
        </div>
        <div className="referralform contactform">
          <Box component="form" autoComplete="off" onSubmit={handleSubmit}>
            <ul>
              <li>
                <TextField
                  id="filled-basic"
                  label="Full Name"
                  name="fullName"
                  variant="standard"
                  value={fullName}
                  onChange={handleName}
                  inputProps={{
                    maxLength: 50,
                    type: "text",
                  }}
                  error={!!nameError}
                  helperText={nameError}
                  onKeyDown={(event) => {
                    if (!ALPHA_REGEX.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  disabled={loading}
                />
              </li>
              <li>
                <TextField
                  id="filled-basic"
                  label="Email Id"
                  name="emailId"
                  value={emailId}
                  variant="standard"
                  onChange={handleEmail}
                  error={!!emailError}
                  helperText={emailError}
                  onKeyDown={(event) => {
                    if (!reg.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  disabled={loading}
                />
              </li>
              <li>
                <TextField
                  id="filled-basic"
                  label="Mobile Number"
                  variant="standard"
                  name="mobileNumber"
                  value={mobileNumber}
                  onChange={handleMobile}
                  error={!!mobileError}
                  helperText={mobileError}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, e.target.maxLength);
                  }}
                  inputProps={{
                    minLength: 10,
                    maxLength: 10,
                    type: "number",
                  }}
                  disabled={loading}
                />
              </li>
              <li>
                {" "}
                <FormControl className="w-100">
                  <InputLabel id="demo-simple-select-label">
                    Category
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="category"
                    value={category}
                    label="Category"
                    error={!!catError}
                    helperText={catError}
                    onChange={handleCategory}
                    className="border-bottom border-color radius-0"
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 300,
                          lineHeight: "12px",
                        },
                      },
                    }}
                  >
                    {lCategoryList?.map((item) => {
                      return <MenuItem value={item.name}>{item.name}</MenuItem>;
                    })}
                  </Select>
                  <FormHelperText sx={{ color: "red" }}>
                    {catError}
                  </FormHelperText>
                </FormControl>
              </li>
              <li>
                {" "}
                <FormControl className="w-100">
                  <InputLabel id="demo-simple-select-label">
                    SubCategory
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="subCategory"
                    value={subCategory}
                    label="Sub Category"
                    error={!!subCatError}
                    helperText={subCatError}
                    onChange={handleSubCategory}
                    className="border-bottom border-color radius-0"
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 300,
                          lineHeight: "12px",
                        },
                      },
                    }}
                  >
                    {subCate?.map((item) =>
                      item?.subCat?.map((item) => {
                        return (
                          <MenuItem value={item.subCatName}>
                            {item.subCatName}
                          </MenuItem>
                        );
                      })
                    )}
                  </Select>
                  <FormHelperText sx={{ color: "red" }}>
                    {subCatError}
                  </FormHelperText>
                </FormControl>
              </li>
              <li>
                <TextField
                  id="filled-textarea"
                  label="Your Message"
                  multiline
                  // rows={4}
                  variant="outlined"
                  name="description"
                  value={description}
                  onChange={handleDescription}
                  fullWidth
                  className="border-bottom border-color radius-0 custom-textarea"
                />
                <FormHelperText sx={{ color: "red" }}>
                  {descriptionError}
                </FormHelperText>
              </li>
            </ul>
            <div className="mbtn">
              <div className="col-lg-3 col-6">
                <div className="btnn w-100 p-0">
                  <button type="submit">Submit</button>
                </div>
              </div>
            </div>
          </Box>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
