import { Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export const DematAccountOrderExecution = () => {
  return (
    <>
      <div
        className="webcent"
        id="Account"
        data-aos="fade-in"
        data-aos-offset="10"
        data-aos-duration="600"
        data-aos-once="true"
        data-aos-easing="ease-in-sine"
      >
        <div className="container">
          <div className="row">
            <div className="web-container">
              <div className="title">
                <h3>How Order is Executed on a Demat Account?</h3>
              </div>
              <div className="iconrow">
                <Typography variant="body1">
                Once a buy order is executed for any shares through a trading account, the depository
participant will forward the order request to the stock exchange (NSE or BSE).
                </Typography>
                <Typography variant="body1">
                The selected exchange matches the buy order with a sell request from another trader. Once the
buy and sell order is matched, the request goes to a clearing house for settling the trade.
                </Typography>
                <Typography variant="body1">
                At the end of the trading day, the shares will get credited to the buyer’s demat account, and at
the same time, the seller’s demat account will get debited. So, both accounts work hand in hand
for a smooth flow of transactions.
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
