import React from "react";
import { Tooltip, Box, styled, tooltipClasses } from "@mui/material";
import QrCodeIcon from "@mui/icons-material/QrCode";
import DynamicQr from "./DynamicQr";

const HoverImageQRCode = () => {
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "rgba(0, 0, 0, 0)",
      color: "rgba(0, 0, 0, 0)",
      fontSize: 11,
    },
  }));
  return (
    <LightTooltip title={<DynamicQr />}>
      <div style={{ marginBottom: 5 }}>
        <div className="cvdiv">
          <QrCodeIcon
            sx={{
              fontSize: 30,
              cursor: "pointer",
            }}
          />
        </div>
      </div>
    </LightTooltip>
  );
};

export default HoverImageQRCode;
