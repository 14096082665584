import React from "react";
import { Box, Typography } from "@mui/material";
import { QRCode } from "react-qrcode-logo"; // Import react-qrcode-logo
import Logo from "../images/smc_ico.webp";

export default function DynamicQr() {
  // URL for the dynamic redirection API
  const dynamicLinkURL = "https://www.smctradeonline.com/download"; // Replace with your dynamic URL

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: 2,
        background: "linear-gradient(80deg, #405cb2, #44b6a7)",
        borderRadius: "8px",
        width: 200,
        boxShadow: 3,
      }}
    >
      <Typography
        sx={{
          color: "#FFF",
          marginBottom: 2,
          textAlign: "center",
          fontSize: 17,
        }}
      >
        Scan to download Ace App
      </Typography>

      {/* Use react-qrcode-logo for QR code with logo */}
      <QRCode
        value={dynamicLinkURL} // The dynamic URL you want to encode
        size={150} // Size of the QR code
        logoImage={Logo} // Path to your logo/image
        logoWidth={40} // Adjust logo size inside QR code
        logoHeight={40} // Adjust logo size inside QR code
        quietZone={8} // Optional: Adjust space around QR code
        logoPadding={1}
        logoPaddingStyle="circle"
        qrStyle="dots"
      />
    </Box>
  );
}
