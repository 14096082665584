import { Typography,Box } from "@mui/material";
import React from "react";

const DematAccountKeyTerms = () =>{
  const dematAccountKeyTerms = [
    {"stepNo":1,"title":"ISIN (International Securities Identification Number)","stepName":"ISIN is a unique 12-digit alphanumeric code that is allotted differently to every security for easy identification at the time of trading and settlement."},
    {"stepNo":2,"title":"DP (Depository Participant)","stepName":"A DP (a stockbroker) acts as an intermediary between the investor and the depository. It allows easy account opening, de or re-materialization of securities."},
    {"stepNo":3,"title":"Dematerialization","stepName":"Dematerialization is a process of converting physical share certificates into Demat form, where in dematerialization charges are applied."},
    {"stepNo":4,"title":"Rematerialization","stepName":"When you choose to convert your Demat share certificates into physical share certificates, it is called rematerialization."},
    {"stepNo":5,"title":"BOID (Beneficial Owner Identification Number)","stepName":"It is a unique 16-digit number allotted to each demat account holder and is assigned by the DP. It helps in the identification of a Demat account and transaction of securities."},
    {"stepNo":6,"title":"Demat Account Holding Statement","stepName":"It is sent by the DP periodically to each investor containing all the securities transacted, their cost of buying, and market value."},
]
    return(
        <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="authorised">
              <div className="container">
                <div className="row">
                  <div className="web-container">
                    <div className="title">
                      <h3>Key Terms Related to Demat Account</h3>
                    </div>
                    <div className="iconrow">
                      {dematAccountKeyTerms.map(step=>
                    <Box
                        p={1}
                        borderRadius={2}
                        bgcolor="#FFF"
                        display="flex"
                        flexDirection="row"
                        // alignItems="center"
                        height="auto"
                    // textAlign="center"
                    >
                        {/* Number */}
                        <Box
                            bgcolor="#C6F5EE"
                            p={2}
                            borderRadius="50%"
                            width={30}
                            height={30}
                            justifyContent="center"
                            alignItems="center"
                            display="flex"
                        >
                            <Typography
                                variant="h6"
                                fontWeight="bold"
                                color="#058C7A"

                            // gutterBottom
                            >
                                {step?.stepNo}
                            </Typography>
                        </Box>

                        {/* Title */}
                        <Box sx={{ pl: 2 }}>
                            <Typography lineHeight={1} sx={{paddingBottom:1}} variant="body1" fontWeight="bold" gutterBottom={false}>
                                 {step?.title}
                            </Typography>
                            <Typography lineHeight={1} variant="body1" color="textSecondary">
                                  {step?.stepName}
                            </Typography>
                            
                        </Box>
                    </Box>
                    )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    )
}
export default DematAccountKeyTerms;