import React, { useEffect } from "react";
import { getQrScan } from "../utils/APICollection";

const DownloadRedirect = () => {
  const onQrScan = async (deviceType) => {
    try {
      const { data } = await getQrScan(deviceType);
    } catch (error) {}
  };

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      onQrScan("android");
      // Redirect to Google Play Store
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.smc.ace";
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      onQrScan("iphone");
      // Redirect to Apple App Store
      window.location.href =
        "https://apps.apple.com/in/app/smc-ace-2-0/id6451310604";
    } else {
      // Fallback: General download page
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.smc.ace";
    }
  }, []);

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h1>Redirecting to the App Store...</h1>
      <p>
        If you are not redirected,{" "}
        <a href="https://play.google.com/store/apps/details?id=com.smc.ace">
          click here
        </a>
        .
      </p>
    </div>
  );
};

export default DownloadRedirect;
