import { Typography,Box } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export const DematAccountOpenSteps = () => {
    const dematAccountOpeningSteps = [
        {"stepNo":1,"stepName":"Enter your mobile number and click on the open free demat account tab."},
        {"stepNo":2,"stepName":"Fill out the free demat account opening form by giving your details such as name, email ID, PAN card number, Aadhaar card number, etc."},
        {"stepNo":3,"stepName":"For verification, enter the OTP sent to your mobile number and email address."},
        {"stepNo":4,"stepName":"For ID proof, upload your PAN Card, or Aadhaar card. Do note that a PAN card is a necessity to open demat account."},
        {"stepNo":5,"stepName":"For address proof, upload your Aadhaar card, or passport, or electricity bill."},
        {"stepNo":6,"stepName":"For bank account details, give your bank statement or passbook image."},
        {"stepNo":7,"stepName":"Upload the scanned image of your signature on white blank paper."},
        {"stepNo":8,"stepName":"Review your details and do the E-sign."},
        {"stepNo":9,"stepName":"Wait for the verification for a few minutes or hours."},
        {"stepNo":10,"stepName":"Get your login credentials and start trading."},
    ]
  return (
    <>
      <div
        className="webcent"
        id="Account"
        data-aos="fade-in"
        data-aos-offset="10"
        data-aos-duration="600"
        data-aos-once="true"
        data-aos-easing="ease-in-sine"
      >
        <div className="container">
          <div className="row">
            <div className="web-container">
              <div className="title">
                <h3>Steps to Open Demat Account</h3>
              </div>
              <div className="iconrow">
                <Typography variant="h6">
                    You can open free demat account within a few minutes with the <Link to={"https://www.smctradeonline.com/"}>SMC Global Securities</Link> or on
                    the <Link to={"https://onelink.to/rt7sdu"}>SMC ACE App</Link>
                </Typography>
                {dematAccountOpeningSteps.map(step=>
                    <Box
                        p={1}
                        borderRadius={2}
                        bgcolor="#FFF"
                        display="flex"
                        flexDirection="row"
                        // alignItems="center"
                        height="auto"
                    // textAlign="center"
                    >
                        {/* Number */}
                        <Box
                            bgcolor="#C6F5EE"
                            p={2}
                            borderRadius="50%"
                            width={30}
                            height={30}
                            justifyContent="center"
                            alignItems="center"
                            display="flex"
                        >
                            <Typography
                                variant="body1"
                                fontWeight="bold"
                                color="#058C7A"

                            // gutterBottom
                            >
                                {step?.stepNo}
                            </Typography>
                        </Box>

                        {/* Title */}
                        <Box sx={{ pl: 2 }}>
                            <Typography variant="body1" color="textSecondary">
                            {step?.stepName}
                            </Typography>
                        </Box>
                    </Box>
                )}
                    
                    
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
