import { Typography,Box } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const DematAccountBenefits = () =>{
  const dematAccountOpeningSteps = [
    {"stepNo":1,"title":"IPOs and Bonus Shares","stepName":"A Demat account provides direct credit of bonus shares, right issues allotments, and dividends.Also, the Demat account helps in getting the allotted IPO shares directly to the account."},
    {"stepNo":2,"title":"Easy-Going Accessibility","stepName":"A Demat account can be accessed anywhere and anytime with stable internet connectivity. It can be easily used on the mobile app or a web-trading platform with no lags."},
    {"stepNo":3,"title":"Portfolio Creation and Management","stepName":"A Demat account becomes a one-stop shop for holding all the instruments in one place, be it stocks or bonds. An automated portfolio is created, which ensures easy management and analysis."},
    {"stepNo":4,"title":"Global Access","stepName":"You get a chance to invest in global securities by opening a free demat account or global demat account with SMC Global Securities."},
    {"stepNo":5,"title":"Risk Reduction","stepName": (
        <>All the demat accounts are regulated by the brokerage firms and depository participants and their functionality is also looked at under the eyes of <Link to="https://blog.smctradeonline.com:7171/what-is-sebi-powers-roles-and-functions-of-sebi/" rel="noopener noreferrer">SEBI</Link>. Hence, the chances of fraudulent activity are close to nil.</>
    )},
    {"stepNo":6,"title":"Cost-Effective","stepName":"SMC offers a free demat account opening service and there are also no account maintenance charges in the first year."},
    {"stepNo":7,"title":"Shares Pledging and Leverage","stepName":"The shares held in a Demat account can be pledged to take a loan and you can trade with a higher amount than what you actually hold through leverage."},
    {"stepNo":8,"title":"Easy Transfer","stepName":"It provides an easy transfer of securities to the concerned person or nominee in case of the beneficiary’s untimely death."}
]
    return(
        <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="authorised">
              <div className="container">
                <div className="row">
                  <div className="web-container">
                    <div className="title">
                      <h3>Benefits of Having a Demat Account</h3>
                    </div>
                    <div className="iconrow">
                      {dematAccountOpeningSteps.map(step=>
                    <Box
                        p={1}
                        borderRadius={2}
                        bgcolor="#FFF"
                        display="flex"
                        flexDirection="row"
                        // alignItems="center"
                        height="auto"
                    // textAlign="center"
                    >
                        {/* Number */}
                        <Box
                            bgcolor="#C6F5EE"
                            p={2}
                            borderRadius="50%"
                            width={30}
                            height={30}
                            justifyContent="center"
                            alignItems="center"
                            display="flex"
                        >
                            <Typography
                                variant="h6"
                                fontWeight="bold"
                                color="#058C7A"

                            // gutterBottom
                            >
                                {step?.stepNo}
                            </Typography>
                        </Box>

                        {/* Title */}
                        <Box sx={{ pl: 2 }}>
                            <Typography lineHeight={1} sx={{paddingBottom:1}} variant="body1" fontWeight="bold" gutterBottom={false}>
                                {step?.title}
                            </Typography>
                            <Typography lineHeight={1} variant="body1" color="textSecondary">
                                {step?.stepName}
                            </Typography>
                            
                        </Box>
                    </Box>
                    )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    )
}
export default DematAccountBenefits;