import React, { useState, useEffect } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import DematForm from "../../../../components/DematForm";
import { Form } from "../../../../components/Form";
import { Tabs, TabList, TabPanel } from "react-tabs";
import Slider from "react-slick";
import { LineCharts } from "../../../../charts/LineChart";
import TradingViewWidget from "../../../../charts/Tradingchart";
import { Attachement } from "../../../../images/svg/Attachement";
import Footer from "../../../../components/Footer";
import { Context } from "../../../../context";
import { useContext } from "react";
import { CandleStick } from "../../../../charts/CandleStick";
import Header from "../../../../components/Header";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";
import StocksHistoricalPerformance from "./StocksHistoricalPerformance";
import StocksOneYrPerformanceIndicator from "./StocksOneYrPerformanceIndicator";
import StocksOverview from "./StocksOverview";
import StocksRatioOverview from "./StocksRatioOverview";
import StockShareHoldings from "./StockShareHoldings";
import StocksNews from "./StockNews";
import StockMfInvested from "./StockMfInvested";
import StockSimilar from "./StockSimilar";
import StockCompleteDetails from "./StockCompleteDetails";
import StockDetailsSeo from "../stockDetailsSeo";
import {
  GetQuates,
  getBioData,
  getCompanyReturns,
  getCompetitivePeers,
  getHistoricalPerformance,
  getIndicesData,
  getPriceData,
  getStockExchange,
  getStockRatio,
} from "../../../../utils/APICollection";
import StockFinance from "../../../stocks/stockFinance/StockFinance";
import StockFinanceSummary from "../../../stocks/stockFinanceSummary/StockFinanceSummary";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import StockFaqDetails from "./StockFaqDetails";

const StockDetails = () => {
  const { id } = useParams();
  const [sidebarTop, setSidebarTop] = useState();
  const [shouldAddClass, setShouldAddClass] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState();
  const [indicesData, setIndicesData] = useState([]);
  const [priceData, setPriceData] = useState([]);
  const [marketData, setMarketData] = useState([]);
  const [tenure, setTenure] = useState("1D");
  const [showChart, setShowChart] = useState(true);
  const [historicalData, setHistoricalData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [ratiosData, setRatiosData] = useState({});

  const {
    exchange,
    setExchange,
    bioData,
    setBioData,
    quatesData,
    setQuatesData,
    companyReturnsData,
    setCompanyReturnsData,
    stockExchange,
    setStockExchange,
  } = useContext(Context);

  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);

  const handleSnackBarClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const timeStamp = [
    { tenure: "1D" },
    { tenure: "1W" },
    { tenure: "1M" },
    { tenure: "1Y" },
    { tenure: "5Y" },
  ];

  const showIndex = [
    "BSE SENSEX 50",
    "BSE 250 SmallCap",
    "BSE 150 MidCap",
    "BSE Largecap",
    "Nifty 50",
    "Nifty 100",
    "Nifty 200",
    "Nifty 500",
    "Nifty Smallcap 50",
    "Nifty Smallcap 100",
    "Nifty Smallcap 250",
    "Nifty Midcap 50",
    "Nifty Midcap 100",
    "Nifty Midcap 250",
    "Nifty Bank",
  ];

  const fetchIndicesData = async () => {
    try {
      const data = await getIndicesData();
      setIndicesData(data?.data?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchBioData = async () => {
    try {
      const data = await getBioData(window.location.pathname.split("/")[3]);
      setBioData(data?.data?.data[0]);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchPriceData = async () => {
    setIsLoading(true);
    try {
      const data = await getPriceData(
        exchange,
        window.location.pathname.split("/")[3],
        tenure
      );
      setPriceData(data?.data?.data);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  const fetchMarketData = async () => {
    try {
      const data = await getCompetitivePeers(
        window.location.pathname.split("/")[3],
        10
      );
      setMarketData(
        data?.data?.data.filter(
          (item) =>
            item.co_code !== parseInt(window.location.pathname.split("/")[3])
        )
      );
    } catch (err) {
      console.log(err);
    }
  };
  const fetchQuatesData = async () => {
    try {
      const data = await GetQuates(
        window.location.pathname.split("/")[3],
        exchange
      );
      setQuatesData(data?.data?.data[0]);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchHistoricalData = async () => {
    try {
      const data = await getHistoricalPerformance(
        window.location.pathname.split("/")[3],
        exchange
      );
      setHistoricalData(data?.data?.data[0]);
    } catch (err) {
      console.log("err", err);
    }
  };
  const fetchCompanyReturns = async () => {
    try {
      const data = await getCompanyReturns(
        exchange,
        tenure,
        window.location.pathname.split("/")[3]
      );
      setCompanyReturnsData(data?.data?.data[0]);
    } catch (err) {
      console.log("error", err);
    }
  };

  const fetchStockExchange = async () => {
    try {
      const data = await getStockExchange(
        window.location.pathname.split("/")[3]
      );
      //setStockExchange()
      setStockExchange(data?.data?.[0]);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchStockRatioData = async () => {
    try {
      const data = await getStockRatio(window.location.pathname.split("/")[3]);
      setRatiosData(data?.data?.data[0]);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchIndicesData();
    fetchBioData();
    fetchMarketData();
    fetchStockExchange();
    fetchStockRatioData();
  }, [window.location.pathname.split("/")[3]]);

  useEffect(() => {
    fetchPriceData();
    fetchCompanyReturns();
  }, [tenure, exchange, window.location.pathname.split("/")[3]]);

  useEffect(() => {
    fetchQuatesData();
    fetchHistoricalData();
  }, [exchange, window.location.pathname.split("/")[3]]);

  const keyValueList = {
    COLUMNNAME: "Financial Year",
    Y202309: "FY 2023-09",
    Y202303: "FY 2023-03",
    Y202209: "FY 2022-09",
    Y202203: "FY 2022-03",
    202303: "Mar 2023",
    202203: "Mar 2022",
    202103: "Mar 2021",
    202003: "Mar 2020",
    201903: "Mar 2019",
    2023: "1-Year",
    2021: "3-Year",
    2019: "5-Year",
  };

  useEffect(() => {
    if (stockExchange?.nse_listed_flag === "Y") {
      setExchange("NSE");
    } else {
      setExchange("BSE");
    }
  }, [stockExchange]);

  useEffect(() => {
    if (stockExchange?.nse_listed_flag === "Y") {
      setExchange("NSE");
    } else {
      setExchange("BSE");
    }
  }, [stockExchange]);

  useEffect(() => {
    const sidebarEl = document.querySelector(".opendemat");

    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const shouldAdd = scrollTop >= sidebarTop - 10;

      setShouldAddClass(shouldAdd);
    };

    if (sidebarEl) {
      const sidebarRect = sidebarEl.getBoundingClientRect();
      setSidebarWidth(sidebarRect.width);
      setSidebarTop(sidebarRect.top);

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [sidebarTop]);

  useEffect(() => {
    if (!sidebarTop) return;

    const handleScroll = () => {
      const sidebarEl = document.querySelector(".opendemat");
      const sectionEl = document.querySelector(".ipodashboard");
      const faq_cnt = document.querySelector(".faqcont");

      const iporow = document.querySelector(".ipodashboard .row");
      const scrollTop = window.scrollY;
      const shouldAdd = scrollTop >= sidebarTop - 10;

      setShouldAddClass(shouldAdd);

      if (shouldAdd) {
        sidebarEl.classList.add("is-sticky");
      } else {
        sidebarEl.classList.remove("is-sticky");
      }

      if (
        scrollTop >=
        sectionEl.offsetTop +
          sectionEl.offsetHeight -
          faq_cnt.offsetHeight / 1.8
      ) {
        sidebarEl.classList.remove("is-sticky");
        iporow.classList.add("flex-end");
      } else {
        iporow.classList.remove("flex-end");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [sidebarTop]);

  // const [content, setContent] = useState("NSE");
  const handleClick = () => {
    if (
      stockExchange?.bse_listed_flag === "N" ||
      stockExchange?.nse_listed_flag === "N"
    ) {
      handleSnackBarClick();
    }
    if (exchange === "NSE" && stockExchange?.bse_listed_flag === "Y") {
      setExchange("BSE");
    } else if (stockExchange?.nse_listed_flag === "Y") {
      setExchange("NSE");
    }
  };
  var settings = {
    dots: false,
    navigation: true,
    loop: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 1,
    autoplay: false,
    speed: 300,
    autoplaySpeed: 5000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1380,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 980,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const isMobile = window.innerWidth <= 1040;

  return (
    <>
      <Header />
      <StockDetailsSeo stockExchange={stockExchange} id={id} />

      <section id="banner">
        <div className="banner internal bauto">
          <div className="container">
            <div className="bredcrum">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/invest">Invest</Link>
                </li>
                {stockExchange?.category_name === "ETF" ? (
                  <li style={{ color: "black" }}>ETF</li>
                ) : (
                  <li>
                    <Link to="/invest/equities">Equities</Link>
                  </li>
                )}

                <li>{stockExchange?.company_name}</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <main>
        <section id="main">
          <div className="ipoportfolio">
            <div className="container">
              <div className="web-container">
                <div className="ipodashboard">
                  <div className="row">
                    <div className="ipoleft ipo8box">
                      <div className="ipospace">
                        <div className="webcent">
                          <div className="ipostable">
                            <div className="tablisthead ipotable">
                              <ul>
                                <li>Top Indices</li>
                              </ul>
                            </div>
                            <div className="topstocks">
                              <Slider {...settings}>
                                {indicesData
                                  ?.filter((item) =>
                                    showIndex.some(
                                      (showIndex) =>
                                        showIndex === item.aliasname
                                    )
                                  )
                                  ?.map((data) => (
                                    <div>
                                      <div
                                        className="slide"
                                        style={{
                                          flexWrap: "wrap",
                                          // textAlign: "center",
                                          width: "80%",
                                          float: "left",
                                          margin: "0 10%",
                                          border: "solid 1px #ededed",
                                          padding: "12px",
                                          boxShadow:
                                            "0px 2px 4px 0px rgb(221 221 221 / 50%)",
                                          borderRadius: "10px",
                                          height: "100px",
                                        }}
                                      >
                                        <div className="topstockslist">
                                          <div className="topstockleft">
                                            <p>
                                              <strong>{data?.aliasname}</strong>
                                            </p>
                                            <p>
                                              <i className="finr"></i>
                                              <span>
                                                {(data?.Last).toFixed(2)}
                                              </span>
                                            </p>
                                          </div>
                                          {/* <div
                                            className={
                                              data?.PriceDiff > 0
                                                ? "profit topstockright"
                                                : "loss topstockright"
                                            }
                                          > */}
                                          <div className="topstockright">
                                            <p
                                              className={
                                                data?.PriceDiff > 0
                                                  ? ""
                                                  : "loss"
                                              }
                                            >
                                              {data?.PriceDiff?.toFixed(2)}
                                            </p>
                                            <p
                                              className={
                                                data?.Change > 0 ? "" : "loss"
                                              }
                                            >
                                              {data?.Change?.toFixed(2)}%
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </Slider>
                            </div>
                            <div className="mutualfundoverview">
                              <div className="mdoverviewheading flex-wrap">
                                {/* <div className="ipologo ipotable incsize">
                                  <span>
                                    image used in future <img src={Quant} alt="" />
                                  </span>
                                </div> */}
                                <h1
                                  style={{
                                    fontSize: "19px",
                                    fontWeight: "600",
                                  }}
                                  className="m-0"
                                >
                                  {stockExchange?.company_name}
                                </h1>
                                {/* may be added in future */}
                                {/* <ul>
                                  <li>
                                    <span className="Bookmarkpost"></span>
                                  </li>
                                  <li>
                                    <span className="notification"></span>
                                  </li>
                                  <li>
                                    <span className="sharepost"></span>
                                  </li>
                                </ul> */}
                              </div>
                              <div className="mfgraphchart">
                                <div className="achart">
                                  <div className="charttab">
                                    <Tabs>
                                      <TabPanel></TabPanel>
                                      <TabPanel></TabPanel>
                                      <TabPanel></TabPanel>
                                      <TabPanel></TabPanel>
                                      <TabPanel></TabPanel>
                                      <TabPanel>
                                        <div className="graphpercentage">
                                          <div className="graphnumber">
                                            <div className="graphpercentagetag">
                                              <span>
                                                <i className="finr"></i>
                                                {quatesData?.Price ||
                                                  quatesData?.price}
                                              </span>
                                              {/* <p>annualised</p> */}
                                            </div>
                                            <div className="graphcatdefine">
                                              {/* <p>Electric Utilities</p> */}
                                            </div>
                                          </div>
                                          <div className="graphperiod">
                                            <span
                                              className={
                                                quatesData?.change > 0
                                                  ? "increase"
                                                  : "pricedown"
                                              }
                                            >
                                              {(
                                                (quatesData?.Price ||
                                                  quatesData?.Price) -
                                                (quatesData?.Oldprice ||
                                                  quatesData?.OldPrice)
                                              ).toFixed(2)}
                                              (
                                              {quatesData?.change > 0
                                                ? quatesData?.change?.toFixed(2)
                                                : JSON.stringify(
                                                    quatesData?.change
                                                  )?.slice(1, 5)}
                                              %)
                                            </span>
                                            <p>
                                              <strong></strong>
                                            </p>
                                          </div>
                                        </div>
                                        {/* {showChart ? <LineCharts priceData={priceData} /> : <CandleStick priceData={priceData} />} */}

                                        <LineCharts priceData={priceData} />
                                      </TabPanel>
                                      <TabPanel>
                                        <div className="graphpercentage">
                                          <div className="graphnumber">
                                            <div className="graphpercentagetag">
                                              <span>16.699%</span>
                                              <p>annualised</p>
                                            </div>
                                            <div className="graphcatdefine">
                                              {/* <p>Electric Utilities</p> */}
                                            </div>
                                          </div>

                                          <div className="graphperiod">
                                            <span
                                              className={
                                                quatesData?.change > 0
                                                  ? "increase"
                                                  : "pricedown"
                                              }
                                            >
                                              {(
                                                (quatesData?.open_Price ||
                                                  quatesData?.Open_Price) -
                                                (quatesData?.Oldprice ||
                                                  quatesData?.OldPrice)
                                              ).toFixed(2)}
                                              (
                                              {quatesData?.change > 0
                                                ? quatesData?.change?.toFixed(2)
                                                : JSON.stringify(
                                                    quatesData?.change
                                                  )?.slice(1, 5)}
                                              %)
                                            </span>
                                            <p>
                                              <strong></strong>
                                            </p>
                                          </div>
                                        </div>
                                        <LineCharts priceData={priceData} />
                                      </TabPanel>
                                      <TabPanel>
                                        <div className="graphpercentage">
                                          <div className="graphnumber mb-2">
                                            <div className="graphpercentagetag">
                                              <span>16.6900%</span>
                                              <p>annualised</p>
                                            </div>
                                            <div className="graphcatdefine">
                                              {/* <p>Electric Utilities</p> */}
                                            </div>
                                          </div>
                                          {/* <div className="graphperiod">
                                            <span className="increase">
                                              +0.46%
                                            </span>
                                            <p>
                                              <strong>1D</strong>
                                            </p>
                                          </div> */}
                                        </div>
                                        {/* <CandleStick /> */}
                                        <TradingViewWidget />
                                      </TabPanel>

                                      <>
                                        <div className="graphpercentage">
                                          <div className="graphnumber ">
                                            {stockExchange?.category_name !==
                                              "ETF" && (
                                              <div className="graphpercentagetag">
                                                <span>
                                                  <i className="finr"></i>
                                                  {
                                                    companyReturnsData?.CurrPrice
                                                  }
                                                </span>
                                                <p>{tenure} Returns</p>
                                              </div>
                                            )}
                                            {exchange === "NSE" &&
                                              stockExchange?.category_name !==
                                                "ETF" && (
                                                <div className="graphcatdefine d-flex gap-3 align-items-center">
                                                  {/* sector added in future <p>Electric Utilities</p> */}
                                                  <NavLink
                                                    to={`/invest/equities/option-chain/${
                                                      stockExchange?.code_symbol
                                                    }/${
                                                      window.location.pathname.split(
                                                        "/"
                                                      )[3]
                                                    }`}
                                                  >
                                                    <p>
                                                      <Attachement /> Option
                                                      Chain
                                                    </p>
                                                  </NavLink>
                                                </div>
                                              )}
                                          </div>
                                          {stockExchange?.category_name !==
                                            "ETF" && (
                                            <div className="graphperiod">
                                              {/* <span className="increase"> */}
                                              <span
                                                className={
                                                  companyReturnsData?.netchng >
                                                  0
                                                    ? "increase"
                                                    : "pricedown"
                                                }
                                              >
                                                {companyReturnsData?.netchng?.toFixed(
                                                  2
                                                )}{" "}
                                                (
                                                {companyReturnsData?.currchange?.toFixed(
                                                  2
                                                ) ||
                                                  companyReturnsData?.R1WRet?.toFixed(
                                                    2
                                                  ) ||
                                                  companyReturnsData?.R1MRet?.toFixed(
                                                    2
                                                  ) ||
                                                  companyReturnsData?.R1YearRet?.toFixed(
                                                    2
                                                  ) ||
                                                  companyReturnsData?.R5YearRet?.toFixed(
                                                    2
                                                  )}
                                                %)
                                              </span>
                                              <p>
                                                <strong>{tenure}</strong>
                                              </p>
                                            </div>
                                          )}
                                        </div>
                                        {showChart ? (
                                          <LineCharts
                                            priceData={priceData}
                                            tenure={tenure}
                                            isLoading={isLoading}
                                          />
                                        ) : (
                                          <CandleStick
                                            tenure={tenure}
                                            priceData={priceData}
                                            isLoading={isLoading}
                                          />
                                        )}
                                      </>

                                      <TabList>
                                        {/* <span
                                          className="borderview"
                                          // onClick={handleClick}
                                        >
                                          {exchange}
                                        </span>
                                        {timeStamp?.map((time) => (
                                          <Tab
                                            style={{
                                              width: "20px",
                                            }}
                                            onClick={() =>
                                              setTenure(time.tenure)
                                            }
                                          >
                                            {time.tenure}
                                          </Tab>
                                        ))} */}

                                        {/* {showChart ? <Tab className="borderview candlestick" onClick={() => setShowChart(!showChart)}></Tab> : <Tab className="borderview" onClick={() => setShowChart(!showChart)}><ShowChartIcon /></Tab>} */}
                                        {/* {showChart ? (
                                          <Tab
                                            onClick={() =>
                                              setShowChart(!showChart)
                                            }
                                            className="borderview"
                                          >
                                            <BarChartIcon />
                                          </Tab>
                                        ) : (
                                          <Tab
                                            onClick={() =>
                                              setShowChart(!showChart)
                                            }
                                            className="borderview"
                                          >
                                            <ShowChartIcon />
                                          </Tab>
                                        )} */}
                                        {/* <Tab className="borderview candlestick"></Tab> */}
                                        {/* trading view data will be used in future */}
                                        {/* <span className="borderview">
                                          Technical
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            fill="currentColor"
                                            className="gr1TPFSIcon"
                                            {...props}
                                          >
                                            <path
                                              fill="none"
                                              d="M0 0h24v24H0z"
                                            />
                                            <path d="M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z" />
                                          </svg>
                                        </span> */}
                                      </TabList>
                                    </Tabs>
                                    <Stack
                                      direction={"row"}
                                      justifyContent={"space-between"}
                                      alignItems={"center"}
                                    >
                                      {/* <div
                                        className="borderviewnew"
                                        onClick={handleClick}
                                      >
                                        {exchange}
                                      </div> */}
                                      <div className="exchangebuttons">
                                        <span
                                          onClick={handleClick}
                                          className={
                                            exchange === "NSE"
                                              ? "exchangebuttondesign"
                                              : "unselectedbuttondesign"
                                          }
                                        >
                                          NSE
                                        </span>
                                        <span
                                          onClick={handleClick}
                                          className={
                                            exchange === "BSE"
                                              ? "exchangebuttondesign"
                                              : "unselectedbuttondesign"
                                          }
                                        >
                                          BSE
                                        </span>
                                      </div>
                                      {timeStamp?.map((time) => (
                                        <div
                                          className={
                                            tenure == time.tenure
                                              ? "charty"
                                              : "chartyn"
                                          }
                                          onClick={() => setTenure(time.tenure)}
                                        >
                                          {time.tenure}
                                        </div>
                                      ))}
                                      {/* candle and barchart toggle will be displayed in future */}
                                      {/* {showChart ? (
                                        <div
                                          onClick={() =>
                                            setShowChart(!showChart)
                                          }
                                          className="borderviewnew"
                                          style={{ marginLeft: 5 }}
                                        >

                                          <Tab className="borderview candlestick"></Tab>
                                        </div>
                                      ) : (
                                        <div
                                          onClick={() =>
                                            setShowChart(!showChart)
                                          }
                                          className="borderviewnew"
                                          style={{ marginLeft: 5 }}
                                        >
                                          <ShowChartIcon />
                                        </div>
                                      )} */}
                                    </Stack>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {stockExchange?.category_name !== "ETF" && (
                          <StockCompleteDetails
                            bioData={bioData}
                            text={"About"}
                          />
                        )}
                        {stockExchange?.category_name !== "ETF" && (
                          <StockFinance />
                        )}
                        {stockExchange?.category_name !== "ETF" && (
                          <StockFinanceSummary />
                        )}
                        <div className="webcent">
                          <StocksHistoricalPerformance
                            historicalData={historicalData}
                          />
                          <StocksOneYrPerformanceIndicator
                            historicalData={historicalData}
                            quatesData={quatesData}
                          />
                          <StocksOverview
                            bioData={bioData}
                            historicalData={historicalData}
                            quatesData={quatesData}
                            stockExchange={stockExchange}
                          />
                          <StocksRatioOverview
                            ratiosData={ratiosData}
                            bioData={bioData}
                            stockExchange={stockExchange}
                          />
                        </div>
                        {/* stock finance graph may be used in future */}
                        {/* <StockFinancials /> */}
                        {stockExchange?.category_name !== "ETF" && (
                          <StockShareHoldings />
                        )}
                        {stockExchange?.category_name !== "ETF" && (
                          <StocksNews bioData={bioData} />
                        )}
                        {stockExchange?.category_name !== "ETF" && (
                          <StockMfInvested />
                        )}
                        <StockSimilar marketData={marketData} />
                        {/* <StockCompleteDetails
                          bioData={bioData}
                          text={"About"}
                        /> */}
                        {isMobile && (
                          <div style={{ width: "100%" }}>
                            <Form catID="Stock" />
                          </div>
                        )}
                        <StockFaqDetails
                          bioData={bioData}
                          quatesData={quatesData}
                          ratiosData={ratiosData}
                        />
                      </div>
                    </div>
                    {!isMobile && (
                      <div className="ipoleft ipo4box">
                        {/* <Form catID="Stock" /> */}
                        <DematForm
                          addClass={shouldAddClass}
                          sidebarWidth={sidebarWidth}
                          catID="Stock"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={`Not Listed `}
        action={action}
      />
    </>
  );
};
export default StockDetails;
