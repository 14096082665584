import { Typography } from "@mui/material";

const DematDocumentation = () =>{
    return(
        <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="80"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="keyfeatures">
              <div className="container">
                <div className="row">
                  <div className="web-container">
                    <div className="title">
                      <span>Mandatory Documentation for Demat Account Opening</span>
                    </div>
                    <div className="keyfeaturerow">
                      <div className="keyfeaturecont">
                      <Typography variant="h5">Proof of identity (POI)</Typography>
                      <ul>
                          <li>
                            <span>1</span>
                            <strong>Adhar Card</strong>
                          </li>
                          <li>
                            <span>2</span>
                            <strong>Passport </strong>
                          </li>
                          <li>
                            <span>3</span>
                            <strong>
                              Driving Licence
                            </strong>
                          </li>
                          <li>
                            <span>4</span>
                            <strong>
                              Voter Id Card
                            </strong>
                          </li>
                          <li>
                            <span>5</span>
                            <strong>
                              Pan Card
                            </strong>
                          </li>
                          <li>
                            <span>6</span>
                            <strong>Any other government-issued ID card having your photograph</strong>
                          </li>
                        </ul>
                      </div>
                      <div style={{marginTop:"-40px"}} className="keyfeaturecont">
                        <Typography variant="h5">Proof of address (POA)</Typography>
                        <ul>
                          <li>
                            <span>1</span>
                            <strong>Electricity Bill</strong>
                          </li>
                          <li>
                            <span>2</span>
                            <strong>Bank Account Statement/ Passbook</strong>
                          </li>
                          <li>
                            <span>3</span>
                            <strong>
                              Ration Card
                            </strong>
                          </li>
                          <li>
                            <span>4</span>
                            <strong>
                                Any other document having your current address
                            </strong>
                          </li>
                          <li>
                            <span>5</span>
                            <strong>
                            Income Proof (For F&O segment)
                            </strong>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
    )
}

export default DematDocumentation;