import React from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

const Disclaimer = () => {
  return (
    <>
      <Header />

      <div className="container">
        <div className="bredcrum">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>Disclaimer</li>
          </ul>
        </div>
        <div
          className="webcent p-100 pt-0"
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-duration="600"
          data-aos-once="true"
          data-aos-easing="ease-in-sine"
        >
          <div className="minicontainer">
            <div className="ipodetail">
              <h3 style={{ color: "#4cafa7", fontWeight: 600, marginTop: 20 }}>
                Disclaimer
              </h3>
              <p style={{ fontSize: "medium" }}>
                <strong>SMC Global Securities Ltd</strong> | CIN :
                L74899DL1994PLC063609 | Registered Office: 11/6B, Shanti
                Chamber, Pusa Road, Delhi-110005. | Tel +91-11-30111000 |
                Compliance Officer: Mr. Vikas Sethi | Tel +91-11-30111000 Extn. 340 | 
                Email:-vikassethi@smcindiaonline.com | Customer Care Email
                – smc.care@smcindiaonline.com | Complaint Email –
                igc@smcindiaonline.com| website: www.smctradeonline.com
              </p>

              <p style={{ fontSize: "medium" }}>
                SEBI Reg. No. INZ000199438, Member: NSE (07714), BSE (470), MSEI
                (1002), MCX (8200) & NCDEX (00021). DP SEBI Regn. No.
                CDSL/NSDL-IN-DP-130-2015, SMC Research Analyst Registration-
                INH100001849, Mutual Funds Distributor ARN No. 29345. •Insurance
                services are offered through SMC Insurance Brokers Pvt. Ltd.
                IRDAI Regn. No: DB 272/04 License No. 289 Valid upto 27/01/2026.
                • Real Estate Advisory services are offered through SMC Real
                Estate Advisors Pvt. Ltd.
              </p>
              <p style={{ fontSize: "medium" }}>
                <strong>
                  Disclaimer: Investment in securities market are subject to
                  market risks. Read all the related documents carefully before
                  investing.
                </strong>{" "}
                Mutual Fund investments are subject to market risks. Read all
                scheme related documents carefully. PMS is not offered in
                commodity derivative segment. Insurance is the subject matter of
                solicitation. Registration granted by SEBI and certification
                from NISM in no way guarantee performance of the intermediary or
                provide any assurance of returns to investors. Past performance
                should not be taken as an indication or guarantee of future
                performance, and no representation or warranty, express or
                implied, is made regarding future performance. The price, value
                of and income from any of the securities or financial
                instruments mentioned in this report can fall as well as rise.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Disclaimer;
