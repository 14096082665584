import React from "react";
import axios from "axios";
import {
  MAIN_API_HOST,
  TIMEOUT,
  CMOTS_API_HOST,
  EKYC_API_HOST,
  EKYC_API_VER_HOST,
  X_API_KEY,
} from "./constants";
import { Context } from "../context";

// Updated MAIN_API instance with additional headers
export const MAIN_API = axios.create({
  baseURL: MAIN_API_HOST,
  timeout: TIMEOUT,
  headers: {
    "x-tenantCode": "SMC",
    "x-productCode": "SMCTRADEONLINE",
    "x-apikey": X_API_KEY,
    "X-Content-Type-Options": "nosniff",
    "X-XSS-Protection": "1; mode=block",
    "X-Frame-Options": "SAMEORIGIN",
    "Content-Security-Policy":
      "default-src 'self' https: ; frame-ancestors 'none' ; script-src 'self' ; object-src 'none'",
    "Referrer-Policy": "strict-origin-when-cross-origin",
    "Accept-Encoding": "gzip, deflate",
  },
});

// Updated TALISMA_SMC_SEARCH_API instance with additional headers
export const TALISMA_SMC_SEARCH_API = axios.create({
  baseURL: CMOTS_API_HOST,
  timeout: TIMEOUT,
  headers: {
    "x-tenantCode": "SMC",
    "x-productCode": "SMCTRADEONLINE",
    "x-apikey": X_API_KEY,
    "X-Content-Type-Options": "nosniff",
    "X-XSS-Protection": "1; mode=block",
    "X-Frame-Options": "SAMEORIGIN",
    "Content-Security-Policy":
      "default-src 'self' https: ; frame-ancestors 'none' ; script-src 'self' ; object-src 'none'",
    "Referrer-Policy": "strict-origin-when-cross-origin",
    "Accept-Encoding": "gzip, deflate",
  },
});

// Updated CMOTS_API instance with additional headers
export const CMOTS_API = axios.create({
  baseURL: CMOTS_API_HOST,
  timeout: TIMEOUT,
  headers: {
    "x-tenantCode": "SMC",
    "x-productCode": "SMCTRADEONLINE",
    "x-apikey": X_API_KEY,
    "X-Content-Type-Options": "nosniff",
    "X-XSS-Protection": "1; mode=block",
    "X-Frame-Options": "SAMEORIGIN",
    "Content-Security-Policy":
      "default-src 'self' https: ; frame-ancestors 'none' ; script-src 'self' ; object-src 'none'",
    "Referrer-Policy": "strict-origin-when-cross-origin",
    "Accept-Encoding": "gzip, deflate",
  },
});

// EKYC_API remains unchanged
export const EKYC_API = axios.create({
  baseURL: EKYC_API_HOST,
  timeout: TIMEOUT,
  withCredentials: true,
});

// EKYC_VER_API remains unchanged
export const EKYC_VER_API = axios.create({
  baseURL: EKYC_API_VER_HOST,
  timeout: TIMEOUT,
});

// MAIN_REGISTER_API remains unchanged
export const MAIN_REGISTER_API = axios.create({
  baseURL: MAIN_API_HOST,
  timeout: TIMEOUT,
});

// MAIN_TOKEN_API remains unchanged
export const MAIN_TOKEN_API = axios.create({
  baseURL: MAIN_API_HOST,
  timeout: TIMEOUT,
});

// MAIN_CMOTS_URL remains unchanged
export const MAIN_CMOTS_URL = axios.create({
  baseURL: CMOTS_API_HOST,
  timeout: TIMEOUT,
});

const AxiosInterceptor = () => {
  const { ipAddress } = React.useContext(Context);

  React.useEffect(() => {
    const addCommonHeaders = (config) => {
      config.withCredentials = true;
      config.headers["X-tenantCode"] = "SMC";
      config.headers["ip"] = ipAddress;
      config.headers["x-productCode"] = "SMCTRADEONLINE";
      config.headers["x-apikey"] = X_API_KEY;
      config.headers["X-Content-Type-Options"] = "nosniff";
      config.headers["X-XSS-Protection"] = "1; mode=block";
      config.headers["X-Frame-Options"] = "SAMEORIGIN";
      config.headers["Content-Security-Policy"] =
        "default-src 'self' https: ; script-src 'self' ; object-src 'none' ; frame-ancestors 'none'";
      config.headers["Referrer-Policy"] = "strict-origin-when-cross-origin";
      config.headers["Accept-Encoding"] = "gzip, deflate";
      return config;
    };

    const requestInterceptor = MAIN_REGISTER_API.interceptors.request.use(
      addCommonHeaders,
      (error) => Promise.reject(error)
    );
    const requestInterceptorS3 = MAIN_TOKEN_API.interceptors.request.use(
      addCommonHeaders,
      (error) => Promise.reject(error)
    );
    const requestInterceptorCmots = MAIN_CMOTS_URL.interceptors.request.use(
      addCommonHeaders,
      (error) => Promise.reject(error)
    );

    const responseInterceptor = MAIN_REGISTER_API.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          console.log(error);
        }
        return Promise.reject(error);
      }
    );

    const responseInterceptorS3 = MAIN_TOKEN_API.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          console.log(error);
        }
        return Promise.reject(error);
      }
    );

    return () => {
      MAIN_REGISTER_API.interceptors.request.eject(requestInterceptor);
      MAIN_TOKEN_API.interceptors.request.eject(requestInterceptorS3);
      MAIN_CMOTS_URL.interceptors.request.eject(requestInterceptorCmots);
      MAIN_REGISTER_API.interceptors.response.eject(responseInterceptor);
      MAIN_TOKEN_API.interceptors.response.eject(responseInterceptorS3);
    };
  }, [ipAddress]);

  return null;
};

export { AxiosInterceptor };
