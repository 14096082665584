import { Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export const DematAccountCompanion = () => {
  return (
    <>
      <div
        className="webcent"
        id="Account"
        data-aos="fade-in"
        data-aos-offset="10"
        data-aos-duration="600"
        data-aos-once="true"
        data-aos-easing="ease-in-sine"
      >
        <div className="container">
          <div className="row">
            <div className="web-container">
              <div className="title">
                <h3>Trading Account: A Companion of Demat Account</h3>
              </div>
              <div className="iconrow">
                <Typography variant="body1">
                The Demat account is linked to the trading account for easy trading of securities. A trading
account is a must to execute the order, while a demat account is just a way to hold the
securities. Both trading and demat accounts have their unique numbers.
                </Typography>
                <Typography variant="body1">
                SMC Global Securities offers an all-in-one investment account to open demat account plus a
                trading account plus a mutual fund account.
                </Typography>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
