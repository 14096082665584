import { Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export const AboutDematAccount = () => {
  return (
    <>
      <div
        className="webcent"
        id="Account"
        data-aos="fade-in"
        data-aos-offset="10"
        data-aos-duration="600"
        data-aos-once="true"
        data-aos-easing="ease-in-sine"
      >
        <div className="container">
          <div className="row">
            <div className="web-container">
              <div className="title">
                <h3>What is a Demat Account?</h3>
              </div>
              <div className="iconrow">
                <Typography variant="body1">
                    A Demat account (short form for the dematerialized account) is an electronic-based account to
                    hold shares certificates. This account was introduced in India in 1996 providing a seamless and
                    secure way to hold the equities certificates in online form which were previously held in paper
                    certificates form. Earlier, there were the risks of theft, fraud, misinformation, or loss which are
                    now eliminated by the use of an online demat account.
                </Typography>
                <Typography variant="body1">
                    Open Demat account is a necessity to trade in the stock market because one has to hold the
                    shares in a dematerialized mode only. This account helps with storing or transferring stocks,
                    bonds, mutual funds, futures and options (F&Os), and ETFs (Exchange Traded Funds) without
                    the use of any physical certificates.
                </Typography>
                <Typography variant="body1">
                    There are two depositories in India that provide a demat account opening interface and that also
                    hold your securities in a Demat account, namely,<Link to={"https://nsdl.co.in/"} rel="nofollow" target="_blank">NSDL</Link>(National Securities Depository Limited)
                    and <Link to={"https://www.cdslindia.com/"} rel="nofollow" target="_blank">CDSL</Link> (Central Depository Services Limited).
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
